import PropTypes from 'prop-types';
import { goToLogin, goToPortal, logout } from './api';

const Login = ({ session, location }) => {
  // useEffect(() => {
  //   trackEvent('auth.SSO Login Page Viewed');
  // }, []);

  if (session) {
    const clientParams = new URLSearchParams(location.search);
    if (clientParams.has('logout')) {
      logout();
    } else {
      goToPortal();
    }
    return null; // This is for tests
  } else {
    //trackStartLogin(undefined, isEmployeeSignIn);
    goToLogin();
    return null; // This is for tests
  }
};

Login.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }),
  session: PropTypes.object,
};

export default Login;
